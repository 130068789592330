<template>
  <div class="flex flex-col justify-start flex-1 min-w-0">
    <div
      v-for="(item, index) in values"
      :key="item.id"
      :data-cy="`${name}_multiple_input`"
      class="group relative"
      :class="{ 'w-full flex items-center space-x-2': !!slots.supplemental }"
    >
      <button
        v-if="!item.is_primary && canDelete"
        :role="`removeInput_${index}`"
        class="absolute top-3.5 transform opacity-0 group-hover:opacity-100 w-5 h-5 flex items-center justify-center z-100 border rounded-full bg-gray-100"
        :class="!!slots.supplemental ? 'right-1/2' : 'right-0 translate-x-1/2'"
        @click.prevent="removeInput(item.id)"
      >
        <icon-base icon="x" height="10" width="10" viewBox="0 0 16 16" />
      </button>
      <div
        v-if="hasPrimary"
        class="cursor-pointer absolute top-3.5 h-5 w-5 rounded-full flex items-center justify-center z-40 text-xs font-medium"
        :class="
          !!slots.supplemental
            ? 'right-1/2 transform -translate-x-6'
            : 'right-4'
        "
        @click.prevent="handlePrimaryValue(item.id)"
      >
        <icon-base
          :icon="IconPrimaryIndicator"
          :class="item.is_primary ? 'text-blue-500' : 'text-gray-300'"
        />
      </div>
      <lf-input
        v-maska
        :role="`input_${index}`"
        :placeholder="`${placeholder} ${values.length > 1 ? index + 1 : ''}`"
        :value="item.value"
        :name="`${name}_${item.id}`"
        :type="type"
        :no-autocomplete="!item.is_primary"
        :custom-error="item.error"
        :data-maska="mask"
        data-maska-eager
        :readonly="item?.readonly"
        @keyup="checkFilled"
        @blur="handleBlur"
      />
      <slot name="supplemental" :phone="item" />
    </div>
    <button
      v-if="canCreate"
      type="button"
      class="flex items-center self-start text-primary font-medium space-x-3-4 mb-6 cursor-pointer"
      @click.prevent="addInput"
    >
      <icon-base icon="plus-circle" icon-color="#3769D6" />
      <span>{{ addMoreText }}</span>
    </button>
  </div>
</template>
<script setup lang="ts">
import { makeRandomString } from "@/helpers/formatting";
import type { PropType } from "vue";
import { ref, watch, onMounted, useSlots } from "vue";
import IconPrimaryIndicator from "@/components/icons/IconPrimaryIndicator.vue";
import type { PhoneNumber } from "@/models/workflows";
import type { EmailAddress } from "@/models/IndividualProfile";
import type { PhoneLineType } from "@/enums/applications";
import has from "lodash/has";

const props = defineProps({
  allValues: {
    type: Array as PropType<string[] | PhoneNumber[] | EmailAddress[]>,
    required: true
  },
  type: {
    type: String,
    default: "text"
  },
  name: {
    type: String,
    required: true
  },
  mask: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ""
  },
  addMoreText: {
    type: String,
    required: true
  },
  errorMessages: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  isWfbObject: {
    type: Boolean,
    default: false
  },
  canDelete: {
    type: Boolean,
    default: true
  },
  canCreate: {
    type: Boolean,
    default: true
  },
  canUpdate: {
    type: Boolean,
    default: true
  },
  hasPrimary: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(["primary-changed", "remove-phone"]);

const slots = useSlots();

const hasInput = ref(false);

type InputField = {
  id: string;
  value: string;
  is_primary?: boolean;
  error: string | null;
  readonly: boolean;
  line_type?: PhoneLineType;
};

const isValueEmailAddress = (
  value: PhoneNumber | EmailAddress
): value is EmailAddress => has(value, "email_address");

const values = ref<InputField[]>(
  props.allValues.map((value) => {
    if (props.isWfbObject && typeof value !== "string") {
      const inputField: InputField = {
        id: String(value?.id || ""),
        is_primary: value?.is_primary,
        error: null,
        readonly: !props.canUpdate,
        value: isValueEmailAddress(value)
          ? value?.email_address || ""
          : value?.phone_number || ""
      };
      if (!isValueEmailAddress(value)) {
        inputField.line_type = value?.line_type;
      }
      return inputField;
    }
    return {
      id: makeRandomString(),
      value,
      error: null,
      readonly: !props.canUpdate
    } as InputField;
  })
);

const addInput = () => {
  values.value.push({
    value: "",
    id: makeRandomString(),
    error: null,
    readonly: false
  });
};

const checkFilled = (e: KeyboardEvent) => {
  if ((e.target as HTMLInputElement).value.length) {
    hasInput.value = true;
    return;
  }
  hasInput.value = false;
};

const handleBlur = () => {
  const hasPrimary = values.value.find((val) => val.is_primary);
  if (!hasPrimary && hasInput.value) {
    handlePrimaryValue(values.value[0]?.id);
  }
};

const removeInput = (id: string) => {
  values.value = values.value.filter((item) => item.id !== id);

  if (props.isWfbObject) {
    emit("remove-phone", id);
  }
};

const handlePrimaryValue = (id: string) => {
  values.value = values.value.map((item) => {
    if (item.id === id) {
      emit("primary-changed", item.id);
      return {
        ...item,
        is_primary: true
      };
    }
    return {
      ...item,
      is_primary: false
    };
  });
};

const makeFirstValuePrimary = () => {
  values.value[0].is_primary = true;
  emit("primary-changed", values.value[0].id);
};

/**
 * Error messages from backend response do not have field names that match the dynamic field names generated in this component.
 * Additional inputs will be grouped into a separate array and so the response messages for errors look like "additional_email.0"
 * This watcher exists to try to match up error messages from the backend to the correct inputs and create a more appropriate message.
 */
watch(
  () => props.errorMessages,
  () => {
    // set all errors back to null before we check and assign updated errors list
    values.value.forEach((val) => (val.error = null));

    if (props.errorMessages.length) {
      const ADDITIONAL_TERM = "additional_";
      const primaryValueIndex = values.value.findIndex((val) => val.is_primary);

      props.errorMessages.forEach((err) => {
        // if no ADDITIONAL_TERM match then it's the error for primary input
        if (err.indexOf(ADDITIONAL_TERM) === -1) {
          values.value[primaryValueIndex].error = err;
        } else {
          const messageSplit = err.split(" ");
          const fieldNameIndex = messageSplit.findIndex((word) =>
            word.includes(ADDITIONAL_TERM)
          );
          if (fieldNameIndex !== -1) {
            // backend response for additional names has field names in format "additional_emails.0" as example
            const errorIndex = Number(
              messageSplit[fieldNameIndex].split(".")[1]
            );

            if (errorIndex < primaryValueIndex) {
              messageSplit[fieldNameIndex] = `${props.placeholder} ${
                errorIndex + 1
              }`;
              messageSplit.shift();
              values.value[errorIndex].error = messageSplit.join(" ");
            } else {
              messageSplit[fieldNameIndex] = `${props.placeholder} ${
                errorIndex + 2
              }`;
              messageSplit.shift();
              values.value[errorIndex + 1].error = messageSplit.join(" ");
            }
          }
        }
      });
    }
  },
  { deep: true }
);

onMounted(() => {
  if (!values.value.length) {
    addInput();
  }

  if (props.isWfbObject && values.value.length) {
    const primaryValue = values.value.find((value) => value.is_primary);

    if (primaryValue && primaryValue.id) {
      emit("primary-changed", primaryValue.id);
    } else {
      makeFirstValuePrimary();
    }
  } else {
    makeFirstValuePrimary();
  }
});
</script>
